<template>
  <v-container fluid>
    <h1 class="text-h1">單位維護</h1>
    <div class="d-flex my-5 justify-space-between align-center">
      <div class="d-flex align-center">
        <v-text-field
          class="mr-2 searchField inputWhiteBg"
          v-model="searchText"
          outlined
          dense
          hide-details
          placeholder="搜尋單位、群組、稽核人員"
        ></v-text-field>
        <v-btn color="primary" @click="getList">搜尋</v-btn>
      </div>
      <div class="d-flex align-center">
        <v-btn
          v-if="rolesCheck('unitMaintainGroup')"
          color="primary"
          class="mr-2"
          @click="groupDialog = true"
          >群組管理</v-btn
        >
        <material-date-picker
          :date="date"
          class="inputWhiteBg"
          label="選擇時間"
          @dateCange="dateCange"
          @update="getList"
          range
        ></material-date-picker>
      </div>
    </div>
    <!-- {{items}} -->
    <!-- {{items[0].auditor}} -->

    <material-table
      class="evelatioin-1"
      :headers="headers"
      :items="items"
      hide-default-footer
      :options.sync="options"
      :items-per-page="-1"
    >
      <template #auditor="{ item }">
        <span v-for="(a, idx) in item.auditor" :key="idx">
          {{ a.name }}
        </span>
      </template>

      <template #updatedAt="{ item }">
        {{ dateFormate(item.updatedAt) }}
      </template>
      <template #actions="{ item }">
        <v-icon v-if="rolesCheck('usersEdit')" @click="openUnitSet(item)"
          >mdi-pencil</v-icon
        >
      </template>
    </material-table>

    <material-pagination :totalPages="totalPages" @pageFilter="pageFilter">
    </material-pagination>

    <material-dialog
      v-model="unitSetDialog"
      title="個別單位設定"
      max-width="500"
    >
      <material-Input
        noBorder
        outlined
        prependTitle="名稱"
        v-model="unitSetData.unit"
      >
      </material-Input>
      <material-select
        v-model="unitSetData.group"
        prependTitle="群組"
        label="選擇群組"
        item-value="name"
        item-text="name"
        :items="groupList"
      ></material-select>

      <material-select
        v-model="unitSetData.auditor"
        prependTitle="稽核人員"
        label="選擇稽核人員"
        :items="auditList"
        item-value="id"
        item-text="name"
        return-object
        multiple
        selection
      >
        <template #prepend-item>
          <v-list-item ripple @mousedown.prevent @click="toggle">
            <v-list-item-action>
              <v-icon
                :color="unitSetData.auditor.length > 0 ? 'indigo darken-4' : ''"
              >
                {{ icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title> Select All </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>

        <template #selection="{ index }">
          <span v-if="index === 0" class="grey--text text-caption">
            已選擇
            <span class="error--text">{{ unitSetData.auditor.length }}</span> 人
          </span>
        </template>
      </material-select>
      <!-- <span v-for="audit in unitSetData.auditor" :key="audit.id">
        {{ audit }}
      </span> -->
      <span v-for="(a, idx) in unitSetData.auditor" :key="idx">
        {{ a.name }}
      </span>

      <template #actions>
        <v-spacer></v-spacer>
        <btn-cancel @click="unitSetDialog = false">取消</btn-cancel>
        <v-btn @click="editUnit" depressed color="success">儲存</v-btn>
      </template>
    </material-dialog>

    <material-dialog v-model="groupDialog" title="群組管理" max-width="440">
      <div class="text-right mb-5">
        <v-btn @click="addGroup" color="primary" :disabled="hasEmptyGroup"
          >新增群組</v-btn
        >
      </div>
      <!-- {{ groupItems }} -->
      <v-data-table
        :headers="groupHeaders"
        :items="groupList"
        class="elevation-1"
        hide-default-footer
      >
        <template #[`item.groupName`]="{ item }">
          <div @dblclick="toggleGroupEdit(item)" style="min-height: 20px">
            <div v-if="item.edit" class="d-flex align-center">
              <v-text-field
                @blur="editGroupItem(item)"
                v-model="editingItem"
                class="mr-2"
              >
              </v-text-field>
              <v-btn outlined color="primary" small @click="editGroupItem(item)"
                >確認</v-btn
              >
            </div>
            <span v-else>
              {{ item.name }}
            </span>
          </div>
        </template>
        <!-- TODO: del alert -->
        <template #[`item.groupAction`]="{ item }">
          <v-icon
            class="pointer mr-2"
            @click="
              delItem = item;
              deleteGroupDialog = true;
            "
            >mdi-delete</v-icon
          >
        </template>
      </v-data-table>
      <template #actions>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          color="primary"
          class="mt-3"
          @click="groupDialog = false"
          >關閉</v-btn
        >
      </template>
    </material-dialog>

    <material-dialog
      v-model="deleteGroupDialog"
      title="刪除群組"
      max-width="440"
    >
      <span
        >您確定要刪除群組「<span style="color: #1867c0">{{ delItem.name }}</span
        >」嗎？</span
      >

      <alert-card>
        <p>已設定為此群組之單位在群組刪除後，將會自動移除群組</p>
      </alert-card>

      <template #actions>
        <v-spacer></v-spacer>
        <btn-cancel @click="deleteGroupDialog = false">取消</btn-cancel>
        <v-btn @click="delGroup" depressed color="error">刪除群組</v-btn>
      </template>
    </material-dialog>

    <material-dialog v-model="delfailDialog" title="提示" max-width="440">
      <span>此群組已被引用，無法刪除</span>

      <template #actions>
        <v-spacer></v-spacer>
        <v-btn @click="delfailDialog = false" depressed color="success"
          >我知道了</v-btn
        >
      </template>
    </material-dialog>
  </v-container>
</template>
<script>
import {
  apiGetUnit,
  apiSaveGroup,
  apiDeleteGroup,
  apiEditUnit,
  apiGetGroup,
  apiGetUsers,
} from "../../api/settings";
import MaterialDatePicker from "../../components/MaterialDatePicker.vue";
import MaterialDialog from "../../components/MaterialDialog.vue";
import {
  searchMixin,
  versionMixin,
  dateFormateMixin,
  checkRolesMixin,
} from "../../helper";
import AlertCard from "../auditField/components/AlertCard.vue";

export default {
  name: "UnitMaintain",
  mixins: [searchMixin, dateFormateMixin, checkRolesMixin],
  data() {
    return {
      searchText: "",
      headers: [
        { text: "單位", value: "unit" },
        { text: "單位代碼", value: "unitCode" },
        { text: "群組", value: "group" },
        { text: "稽核人員", value: "auditor" },
        { text: "更新時間", value: "updatedAt" },
        { text: "操作", value: "actions" },
      ],
      items: [
        // {
        //   unit: "台中分公司",
        //   unitCode: "Wade Warren",
        //   group: "稽核室",
        //   auditor: ["王裕嚴"],
        //   updatedAt: "2022-06-02 11:23:20",
        // },
        // {
        //   unit: "損害防阻暨勞工安全衛生部",
        //   unitCode: "Wade Warren",
        //   group: "核保中心",
        //   auditor: ["李芳庭", "林芳庭"],
        //   updatedAt: "2022-06-02 11:23:20",
        // },
      ],
      date: [],
      unitSetDialog: false,
      groupList: [
        // { id: 1, name: "分公司", edit: false },
        // { id: 2, name: "核保中心", edit: false },
        // { id: 3, name: "稽核室", edit: false },
      ],
      auditList: [
        // { id: 1, name: "王裕嚴" },
        // { id: 2, name: "李芳庭" },
        // { id: 3, name: "林芳庭" },
      ],
      unitSetData: {
        unit: "",
        group: "",
        auditor: [],
      },
      groupDialog: false,
      groupHeaders: [
        { text: "群組名稱", value: "groupName" },
        { text: "操作", value: "groupAction", width: 100 },
      ],
      editingItem: "",
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["updatedAt"],
        sortDesc: [true],
      },
      totalPages: 1,

      deleteGroupDialog: false,
      delItem: {},

      delfailDialog: false,
    };
  },
  computed: {
    likesAllFruit() {
      return this.unitSetData.auditor.length === this.auditList.length;
    },
    likesSomeFruit() {
      return this.unitSetData.auditor.length > 0 && !this.likesAllFruit;
    },
    icon() {
      if (this.likesAllFruit) return "mdi-close-box";
      if (this.likesSomeFruit) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    hasEmptyGroup() {
      let hasEmpty = false;
      this.groupList.forEach((el) => {
        if (el.text === "") hasEmpty = true;
      });
      return hasEmpty;
    },
  },
  mounted() {
    this.getAuditList();
    this.getGroupList();
    // this.getList();
  },
  watch: {
    options: {
      handler() {
        console.log(this.options);
        this.getList();
      },
    },
  },
  methods: {
    async getAuditList() {
      try {
        let searchData = {
          page: 1,
          size: 999,
          roles: "AUDITOR",
        };
        let res = await apiGetUsers(searchData);
        this.auditList = res.data.content;
      } catch (error) {
        console.log("err apiGetUsers: ", error);
      }
    },
    async getGroupList() {
      try {
        let res = await apiGetGroup();
        this.groupList = res.data;
      } catch (error) {}
    },
    async editUnit() {
      console.log("In editUnit");
      console.log(this.unitSetData);

      let payload = {};
      payload["group"] = this.groupList.find(
        (e) => e.name == this.unitSetData.group
      ).id;
      payload["auditor"] = [];
      for (let i of this.unitSetData.auditor) payload["auditor"].push(i.id);

      console.log("payload: ", payload);
      try {
        let res = await apiEditUnit(this.unitSetData.id, payload);
        console.log("apiEditUnit data: ", res.data);

        this.unitSetDialog = false;
      } catch (error) {}
    },
    pageFilter() {
      let pageParams = arguments[0];
      this.options.page = pageParams.page;
      this.options.itemsPerPage = pageParams.itemsPerPage;
      // this.getList();
    },
    async getList() {
      try {
        let searchData = this.makeUrlData();
        // console.log('search data:', searchData)
        // console.log("searchData: ", searchData)
        let res = await apiGetUnit(searchData);
        // console.log("getAudit data: ", res.data)
        this.items = res.data.content;
        this.totalPages = res.data.totalPages;
      } catch (error) {
        console.log(error);
      }
    },
    displayauditor(auditor) {
      // console.log({ auditor });
      if (auditor) {
        return auditor.join("、");
      }
    },
    /* Datepicker */
    dateCange(val) {
      this.date = val.sort();
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllFruit) {
          this.unitSetData.auditor = [];
        } else {
          let newList = this.auditList.map((el) => el.text);
          this.unitSetData.auditor = newList;
        }
      });
    },
    /* Unit Set */
    openUnitSet(item) {
      this.unitSetDialog = true;
      this.unitSetData = item;
    },
    /* Group API */
    resetEdit() {
      this.groupList.forEach((el) => (el.edit = false));
    },
    findMaxId() {
      let maxId = -1;
      for (let g of this.groupList) if (g.id > maxId) maxId = g.id;
      return maxId;
    },
    addGroup() {
      this.resetEdit();
      this.editingItem = "";
      this.groupList.push({
        id: this.findMaxId() + 1,
        name: "",
        edit: true,
      });
    },
    editGroupItem(item) {
      // console.log({ item });
      item.name = this.editingItem;
      this.filterEmpty();
      item.edit = false;
      this.editingItem = "";
      this.SaveGroup();
    },
    async SaveGroup() {
      console.log("SaveGroup: ", this.groupList);
      let payload = [];
      for (let g of this.groupList) payload.push({ id: g.id, name: g.name });
      try {
        let res = await apiSaveGroup(payload);
        console.log("SaveGroup data: ", res);
      } catch (error) {
        console.log("err SaveGroup", error);
      }
    },
    toggleGroupEdit(item) {
      this.filterEmpty();
      this.resetEdit();
      item.edit = true;
      this.editingItem = item.text;
    },
    filterEmpty() {
      if (this.editingItem === "") {
        // console.log("hi");
        this.groupList = this.groupList.filter((el) => el.text !== "");
      }
    },
    async delGroup() {
      try {
        let res = await apiDeleteGroup(this.delItem.id);
        this.groupList = this.groupList.filter(
          (el) => el.id !== this.delItem.id
        );
        this.deleteGroupDialog = false;
      } catch (error) {
        console.log("err delGroup: ", error);
        if (error.response.data.error.code == 500) this.delfailDialog = true;
      }
    },
    // 建立過濾網址參數
    makeUrlData() {
      let searchData = {};
      // searchData["page"] = this.options.page;
      searchData["page"] = this.options.page;
      searchData["size"] = this.options.itemsPerPage;
      searchData["sortBy"] = [null, undefined, "", "updatedAt"].includes(
        this.options.sortBy[0]
      )
        ? "updatedAt"
        : this.options.sortBy[0];
      searchData["order"] = this.options.sortDesc[0] == false ? "asc" : "desc";

      let filterData = {};
      if (this.date != null && this.date.length == 2)
        filterData = {
          start: this.date[0],
          end: this.date[1],
        };
      if (this.searchText != "") {
        filterData["keyword"] = this.searchText;
      }
      return { ...searchData, ...filterData };
    },
  },
  components: { MaterialDatePicker, MaterialDialog, AlertCard },
};
</script>
toggle
<style scpoed lang="scss"></style>
